<template>
    <v-app id="bloq-agenda">
        <v-card class="elevation-12" loader-height="6">
            <!-- :loading="loading" :disabled="loading"> -->
            <v-toolbar dark color="primary">
                <v-icon style="margin-right:10px;" size="30" @click="$router.go(-1)">keyboard_return</v-icon>
                <v-toolbar-title>Bloquear Agenda</v-toolbar-title>
                <v-spacer />
                <!-- <v-toolbar-items>
                    <v-btn dark text @click.stop.prevent="$router.push({name: 'login'})">Voltar</v-btn>
                </v-toolbar-items> -->
            </v-toolbar>
            <v-card-text>
                <v-row align="center" class="d-flex flex-column justify-center">
                    <v-col>
                        <v-row class="ml-3 mr-3 mt-3 pl-3 pr-3">
                            <v-menu ref="menu" v-model="miniCalendar" :close-on-content-click="false" reactive
                                transition="scale-transition" offset-y min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model.lazy="formatedDate" label="Data" prepend-inner-icon="event"
                                        outlined readonly v-on="on" />
                                </template>
                                <v-date-picker v-model="auxSelectedDate" locale="pt-BR" scrollable :min="minDate"
                                    color="matchpal" @input="cliqueCalendario" />
                            </v-menu>

                            <!-- <v-select v-model="quadrasSelected" :items="quadras" chips deletable-chips multiple outlined
                                clearable label="Quadras" item-value="id" item-text="nome" /> -->
                        </v-row>
                    </v-col>
                    <v-col>
                        <v-simple-table class="mb-4 pb-12">
                            <template v-slot:default>
                                <thead>
                                    <tr is="transition-group" name="fade">
                                        <th v-for="(dia,i) in dias" :key="i" class="text-center">
                                            <v-checkbox v-model="dia.selected"
                                                :indeterminate="dia.indeterminate"
                                                :disabled="dia.bloqueio"
                                                dense color="white"
                                                @change="selecionaDia(i)">

                                                <template slot="label">
                                                    <h4 class="white--text">{{ dia.data | data }}</h4>
                                                </template>
                                            </v-checkbox>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody is="transition-group" name="fade">
                                    <tr v-for="(linha, i) in tabela" :key="i" class="linha">
                                        <td v-for="(horario, j) in linha" :key="j+5" class="text-center">
                                            <v-checkbox v-if="horario.id" v-model="horariosSelected[j]"
                                                :value="horario.id" :disabled="horario.bloqueio" dense color="#00BCD4"
                                                @change="cliqueHorario(i, j)">
                                                <template slot="label">
                                                    <p style="margin-bottom:-0px">
                                                        <strong>{{ horario.hora_inicio }}</strong>
                                                        - {{ horario.quadra_nome }}
                                                    </p>
                                                </template>
                                            </v-checkbox>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-speed-dial v-model="fab" bottom right direction="top"
            open-on-hover transition="slide-y-reverse-transition">
            <template v-slot:activator>
                <v-btn v-model="fab" color="primary" dark fab>
                    <v-icon v-if="fab">close</v-icon>
                    <v-icon v-else>lock</v-icon>
                </v-btn>
            </template>
            <v-btn fab dark small color="green"
                @click="bloquear">
                <v-icon>check</v-icon>
            </v-btn>
            <v-btn fab dark small color="red"
                @click="limparSelecao">
                <v-icon>delete</v-icon>
            </v-btn>
        </v-speed-dial>

        <Snackbar :snackbar-flag="snackbar.activeFlag" :permanent="snackbar.permanentFlag" :error="snackbar.errorFlag"
            :color="snackbar.color" :hide-close-buttom="snackbar.isConfirmMode">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <template v-slot:message><span v-html="snackbar.text" /></template>
            <template v-slot:actions>
                <WhatsLink v-if="snackbar.phone && !snackbar.isConfirmMode"
                    class="v-btn v-btn--flat v-btn--text theme--light v-size--default indigo--text"
                    :phone="snackbar.phone" :text="snackbar.whatsappMsg" icon="after">Avisar</WhatsLink>
                <v-btn v-if="snackbar.isConfirmMode" color="error"
                    @click.prevent="snackbar.confirm = false; snackbar.activeFlag = 0">Não</v-btn>
                <v-btn v-if="snackbar.isConfirmMode" color="matchpal"
                    @click.prevent="snackbar.confirm = true; snackbar.activeFlag = 0">Sim</v-btn>
            </template>
        </Snackbar>

        <Load :load="load" />
    </v-app>
</template>

<script>
import Utils from "@components/Utils/Utils.vue"
import moment from 'moment'
import 'moment/locale/pt'

export default {
    name: 'BloquearAgenda',
    components: {
        Snackbar: () => import('@components/Snackbar.vue'),
        Load: () => import('@components/Load.vue'),
    },
    filters: {
        data(data) {
            return moment(data).format('DD/MM - dddd')
        }
    },
    mixins: [ Utils ],
    data: () => ({
        //Horários
        auxSelectedDate: moment(new Date()).format('YYYY-MM-DD'),
        selectedDate: undefined,
        horariosSelected: [],
        agenda: [],
        bloqueados: [],

        //Tabela
        dias: [],
        semana: [],
        tabela: [],

        //Select
        quadrasSelected: [],
        quadras: [],

        //MiniCalendar
        openMiniCalendarDrawer: false,
        miniCalendar: false,
        minDate: undefined,

        load: false,
        fab: false,
    }),
    computed: {
        formatedDate: {
            get: function () {
                return moment(this.selectedDate).format('DD/MM/YYYY')
            },
            set: function (date) {
                this.selectedDate = moment(date).format('YYYY-MM-DD')
            }
        }
    },
    mounted() {
        this.load = true
        this.selectedDate = this.auxSelectedDate
        this.minDate = this.auxSelectedDate
        this.fetchHorarios()
    },
    methods: {
        fetchHorarios: function () {
            this.limpaHorarios()
            this.$http({
                method: 'post',
                url: '/apiweb/getHorariosIntervalo',
                data: {
                    data_inicio: moment(this.selectedDate),
                    data_fim: moment(this.selectedDate)
                        .add(6, 'days')
                }
            }).then(response => {
                this.semana = response.data.horarios
                this.quadras = response.data.quadras
                this.quadrasSelected = this.quadras.map(q => q.id)
                for (let i = 0; i < this.semana.length; i++) {
                    this.horariosSelected.push([])
                    this.semana[i].horarios.forEach(
                        horario => (horario.bloqueio = false)
                    )
                }
                this.dias = this.semana.map(dia => ({
                    data: dia.data,
                    selected: false,
                    indeterminate: false
                }))
                let bloqueados = response.data.diasbloqueados
                bloqueados.forEach(bloqueio => {
                    if (bloqueio.horario_id) {
                        this.semana.forEach(dia => {
                            dia.horarios.forEach(horario => {
                                if (bloqueio.horario_id === horario.id) {
                                    horario.bloqueio = true
                                }
                            })
                        })
                    } else {
                        for (let i = 0; i < this.dias.length; i++) {
                            let dia = this.dias[i]
                            if (dia.data === bloqueio.data) {
                                dia.bloqueio = true
                                this.semana[i].horarios.forEach(horario => {
                                    //this.horariosSelected[i].push(horario.id);
                                    horario.bloqueio = true
                                })
                            }
                        }
                    }
                })
                this.carregaTabela()
            })
        },
        carregaTabela() {
            let sizes = this.semana.map(dia => dia.horarios.length)
            const maiorQntHorarios = Math.max.apply(null, sizes)

            for (let i = 0; i < maiorQntHorarios; i++) {
                let indice = 0
                let linha = []
                this.semana.forEach(dia => {
                    var horario = dia.horarios[i]
                    if (horario) {
                        this.quadras.forEach(quadra => {
                            if (quadra.id === horario.quadra_id) {
                                horario.quadra_nome = quadra.nome
                            }
                        })
                        horario.hora_inicio = horario.hora_inicio.substr(0, 5)
                        linha.push(horario)
                    } else {
                        linha.push({
                            hora_inicio: '',
                            id: false,
                            quadra_id: -1,
                            quadra_nome: ''
                        })
                    }
                })
                this.tabela.push(linha)
            }

            this.load = false
        },
        selecionaDia(index) {
            let dia = this.dias[index]
            if (this.quadrasSelected.length || !dia.selected) {
                if (dia.indeterminate) {
                    this.semana[index].horarios.forEach(horario => {
                        if (
                            !this.horariosSelected[index].includes(
                                horario.id
                            ) &&
                            this.quadrasSelected.includes(horario.quadra_id) &&
                            !horario.bloqueio
                        ) {
                            this.horariosSelected[index].push(horario.id)
                        }
                    })
                } else if (dia.selected) {
                    this.semana[index].horarios.forEach(horario => {
                        if (
                            this.quadrasSelected.includes(
                                horario.quadra_id
                            ) && !horario.bloqueio
                        ) {
                            this.horariosSelected[index].push(horario.id)
                        }
                    })
                } else if (!dia.selected) {
                    this.semana[index].horarios.forEach(horario => {
                        let indexOf = this.horariosSelected[index].indexOf(
                            horario.id
                        )
                        if (indexOf != -1 && !horario.bloqueio) {
                            this.horariosSelected[index].splice(indexOf, 1)
                        }
                    })
                }
                dia.indeterminate = false
            } else {
                dia.selected = false //não tá funcionando isso
                this.notify('Selecione ao menos uma quadra!', false)
            }
        },
        cliqueHorario(indiceHorario, indiceDia) {
            let length = this.horariosSelected[indiceDia].length
            if (length === this.semana[indiceDia].horarios.length) {
                this.dias[indiceDia].selected = true
                this.dias[indiceDia].indeterminate = false
            } else if (length > 0) {
                this.dias[indiceDia].selected = false
                this.dias[indiceDia].indeterminate = true
            } else {
                this.dias[indiceDia].selected = false
                this.dias[indiceDia].indeterminate = false
            }
        },
        limpaHorarios() {
            this.horariosSelected = []
            this.dias = []
            this.semana = []
            this.tabela = []
            //this.quadrasSelected = [];
            //this.quadras = [];
            this.agenda = []
        },
        limparSelecao() {
            this.horariosSelected = []
            this.dias.forEach(dia => {
                dia.selected = false
                dia.indeterminate = false
            })
        },
        bloquear() {
            var horarios = []
            for (let i = 0; i < this.dias.length; i++) {
                if (this.horariosSelected[i].length > 0) {
                    horarios.push({
                        data: this.dias[i].data,
                        horarios_id: this.horariosSelected[i]
                    })
                }
            }

            if(!horarios.length) return this.notify('Nenhum novo horário marcado!')

            this.confirm('Você realmente deseja bloquear esses horários?').then(
                confirm => {
                    this.load = true
                    if (!confirm) return (this.load = false)

                    this.$http({
                        method: 'post',
                        url: '/apiweb/bloqueiaHorarios',
                        data: {
                            horarios
                        }
                    }).then(response => {
                        if (response.data.success === true) {
                            var txt = 'Horários bloqueados com sucesso!'
                            if (response.data.reservasAfetadas.length > 0
                                || response.data.fixosAfetados.length > 0)
                                txt += '<br/><i aria-hidden="true" class="v-icon notranslate material-icons">warning</i> '
                                    + 'Faça o cancelamento das reservas nos horários bloqueados na agenda.'
                            else
                                txt += '<br/> Não existem reservas nos horários bloqueados.'
                            this.notify(txt, false)
                            this.fetchHorarios() //otimizar
                        } else {
                            this.notify('Tente novamente!', true)
                        }
                        this.load = false
                    })
                })
        },
        cliqueCalendario() {
            this.miniCalendar = false
            const temAlgumSelected = this.horariosSelected.some(dia => {
                return dia.length > 0
            }) //verifica se tem algum horário selecionado
            if (temAlgumSelected) {
                this.confirm(
                    'Ao selecionar outro dia, os horários selecionados serão descartados. Deseja continuar?')
                    .then(confirm => {
                        if (!confirm) return (this.load = false)
                        this.selectedDate = this.auxSelectedDate
                        this.fetchHorarios()
                    })
            } else {
                this.selectedDate = this.auxSelectedDate
                this.fetchHorarios()
            }
        }
    }
}
</script>

<style lang="sass" scoped>
    @import '@assets/css/global-vars.sass'


    th
        text-align: left
        background-color: #00BCD4

    td,
    th
        border: 1px solid #ddd

    .fade-enter-active, .fade-leave-active
        transition: all 0.3s

    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
        opacity: 0

    .linha
        display: table-row


    #bloq-agenda
        header.v-toolbar
            background-color: $blue !important

        .v-card__text
            padding: 16px 30px

    #bloq-agenda .v-speed-dial
        position: fixed

    #bloq-agenda .v-btn--floating
        position: relative
</style>