<template>
    <v-app id="menu">
        <v-navigation-drawer v-model="openViewModel" fixed temporary right width="350">
            <v-toolbar color="matchpal" style="position: sticky; top: 0; z-index: 10; margin-bottom: 5px;">
                <v-icon class="mr-4" size="32" @click="openViewModel = false">close</v-icon>
                <v-toolbar-title>Menu</v-toolbar-title>
            </v-toolbar>

            <v-list nav>
                <!-- <v-list-item two-line>
                    <v-list-item-avatar>
                        <img src="https://randomuser.me/api/portraits/men/81.jpg">
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title>User Name</v-list-item-title>
                        <v-list-item-subtitle>Administrador</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item> -->

                <!-- <v-divider /> -->

                <v-list-group
                    prepend-icon="store"
                    no-action
                    flat
                >
                    <template v-slot:activator>
                        <v-list-item-title>Estabelecimento</v-list-item-title>
                    </template>

                    <router-link v-slot="{ href, navigate, isActive, isExactActive }"
                        to="/editar-estabelecimento">
                        <v-list-item link dense :class="{ 'router-link-active': isActive,
                                'router-link-exact-active': isExactActive }"
                                @click="navigate">

                            <v-list-item-icon>
                                <v-icon>store</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>Perfil</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>

                    <router-link v-slot="{ href, navigate, isActive, isExactActive }"
                        to="/quadras">
                        <v-list-item link dense :class="{ 'router-link-active': isActive,
                                'router-link-exact-active': isExactActive }"
                                @click="navigate">

                            <v-list-item-icon>
                                <v-icon>sports</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>Quadras</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>

                    <router-link v-slot="{ href, navigate, isActive, isExactActive }"
                        to="/administradores">
                        <v-list-item link dense :class="{ 'router-link-active': isActive,
                                'router-link-exact-active': isExactActive }"
                                @click="navigate">

                            <v-list-item-icon>
                                <v-icon>supervisor_account</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>Administradores</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>

                    <router-link v-slot="{ href, navigate, isActive, isExactActive }"
                        to="/bloquear-agenda">
                        <v-list-item link dense :class="{ 'router-link-active': isActive,
                                    'router-link-exact-active': isExactActive }"
                                    @click="navigate">
                            <v-list-item-icon>
                                <v-icon>lock</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>Bloquear Agenda</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>

                    <router-link v-slot="{ href, navigate, isActive, isExactActive }"
                        to="/historico">
                        <v-list-item link dense :class="{ 'router-link-active': isActive,
                                    'router-link-exact-active': isExactActive }"
                                    @click="navigate">
                            <v-list-item-icon>
                                <v-icon>history</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>Histórico de Ações</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>

                </v-list-group>

                <v-list-item link @click="$emit('mensagemOpen'); openViewModel = false">
                    <v-list-item-icon>
                        <v-icon>fab fa-whatsapp</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Editar mensagens</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <router-link v-slot="{ href, navigate, isActive, isExactActive }"
                    to="/pix">
                    <v-list-item link :class="{ 'router-link-active': isActive,
                            'router-link-exact-active': isExactActive }"
                            @click="navigate">

                        <v-list-item-icon>
                            <v-icon>fa-brands fa-pix</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>Receber com Pix</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>

                <router-link v-slot="{ href, navigate, isActive, isExactActive }"
                    to="/planocontratado">
                    <v-list-item link :class="{ 'router-link-active': isActive,
                                'router-link-exact-active': isExactActive }"
                                @click="navigate">
                        <v-list-item-icon>
                            <v-icon>attach_money</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>Plano contratado</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>

                <router-link v-slot="{ href, navigate, isActive, isExactActive }"
                    to="/notificacoes">
                    <v-list-item link :class="{ 'router-link-active': isActive,
                                'router-link-exact-active': isExactActive }"
                                @click="navigate">
                        <v-list-item-icon>
                            <v-icon>notifications</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>Notificações</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>
                <!-- <router-link v-slot="{ href, navigate, isActive, isExactActive }"
                    to="/foo">
                    <v-list-item link :class="{ 'router-link-active': isActive,
                                'router-link-exact-active': isExactActive }"
                                @click="navigate">
                        <v-list-item-icon>
                            <v-icon>settings</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>Preferências</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link> -->

                <WhatsLink :text="`${estabelecimento.nome} deseja tirar dúvida sobre: `"
                    phone="5555996794845">
                    <v-list-item link>
                        <v-list-item-icon>
                            <v-icon>comment</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>Suporte</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </WhatsLink>

                <!-- <router-link v-slot="{ href, navigate, isActive, isExactActive }"
                    to="/foo">
                    <v-list-item link :class="{ 'router-link-active': isActive,
                                'router-link-exact-active': isExactActive }"
                                @click="navigate">
                        <v-list-item-icon>
                            <v-icon>person</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>Perfil do Usuário</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link> -->

                <v-list-item link @click="$emit('refreshHorarios'); openViewModel = false">
                    <v-list-item-icon>
                        <v-icon>refresh</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Sincronizar</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item link @click="openViewModel = false; dialogInviteOptions = true; getCalendarDate()">
                    <v-list-item-icon>
                        <v-icon>share</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Compartilhar</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item link @click="actionLogout">
                    <v-list-item-icon>
                        <v-icon>logout</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Sair</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

        </v-navigation-drawer>

        <Snackbar :snackbar-flag="activeFlag" permanent
                color="#FFF" :hide-close-buttom="isConfirmMode">
                <template v-slot:message>Você realmente deseja sair?</template>
                <template v-slot:actions>
                    <v-btn v-if="isConfirmMode" color="error"
                        @click.prevent="activeFlag = 0">Não</v-btn>
                    <v-btn v-if="isConfirmMode" color="matchpal"
                        @click.prevent="confirmLogout">Sim</v-btn>
                </template>
        </Snackbar>

        <v-dialog v-model="dialogInviteOptions" content-class="modal-share"
            fullscreen hide-overlay scrollable eager
            transition="dialog-bottom-transition">
            <v-card tile>
                <v-toolbar flat dark color="matchpal" max-height="70px">
                    <v-btn icon dark @click="dialogInviteOptions = false">
                        <v-icon>close</v-icon>
                    </v-btn>

                    <v-toolbar-title class="headline pl-1">
                        Compartilhar
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text class="row justify-center">
                    <div class="col-sm-12 col-md-8">
                        <p class="title">Compartilhar horários livres:</p>

                        <v-menu ref="menu" v-model="miniCalendar"
                            :close-on-content-click="false" reactive
                            transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field v-model="formatedDate" label="Data"
                                    prepend-icon="event" readonly
                                    required v-on="on" />
                            </template>
                            <v-date-picker v-model="selectedDate" locale="pt-BR"
                                scrollable @input="miniCalendar = false; typeInvite = false; fetchHorarios()" />
                        </v-menu>

                        <p v-if="!typeInvite" class="subtitle-1">
                            Carregando...
                        </p>

                        <p v-if="typeInvite" class="subtitle-1">
                            Quais quadras você deseja compartilhar?
                        </p>

                        <v-simple-table v-if="typeInvite" dense>
                            <thead>
                                <tr>
                                    <th @click="toogleAllCourts">
                                        Quadra
                                    </th>
                                    <th class="text-center" @click="toogleAllShift(0)">
                                        Manhã
                                    </th>
                                    <th class="text-center" @click="toogleAllShift(1)">
                                        Tarde
                                    </th>
                                    <th class="text-center" @click="toogleAllShift(2)">
                                        Noite
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="q in quadras" :key="q.id">
                                    <td>
                                        <v-checkbox v-model="q.invite" dense hide-details
                                        :label="q.nome" :value="q.id" @change="courtChange" />
                                    </td>
                                    <td class="text-center">
                                        <v-checkbox v-model="q.shifts2share"
                                            class="mr-5 no-label"
                                            value="0" :disabled="isPastSchedule(0)"
                                            dense hide-details @change="updateShift2share(q)" />
                                    </td>
                                    <td class="text-center">
                                        <v-checkbox v-model="q.shifts2share"
                                            class="mr-5 no-label"
                                            value="1" :disabled="isPastSchedule(1)"
                                            dense hide-details @change="updateShift2share(q)" />
                                    </td>
                                    <td class="text-center">
                                        <v-checkbox v-model="q.shifts2share"
                                            class="no-label"
                                            value="2" :disabled="isPastSchedule(2)"
                                            dense hide-details @change="updateShift2share(q)" />
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>

                        <v-card-actions class="mt-5 pt-4 pb-0 d-flex justify-space-between">
                            <v-btn type="reset"
                                color="error" dark @click="dialogInviteOptions = false">Cancelar
                            </v-btn>

                            <WhatsLink icon="after"
                                class="v-btn v-btn--flat v-btn--text theme--light v-size--default indigo--text matchpal"
                                :text="whatsInviteText">
                                Enviar
                            </WhatsLink>
                        </v-card-actions>
                    </div>
                </v-card-text>

                <div style="flex: 1 1 auto;" />
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
import moment from 'moment'


export default {
    name: 'Menu',
    components: {
        Snackbar: () => import('@components/Snackbar.vue'),
        WhatsLink: () => import('@components/WhatsappLink.vue'),
    },
    props: {
        openFlag: {
            type: Number,
            default: 0,
        },
        estabelecimento: {
            type: Object,
            default: function () {
                return new Object()
            },
        },
    },
    data: () => ({
        openViewModel: false,
        snackbar: false,
        activeFlag: 0,
        isConfirmMode: true,
        dialogInviteOptions: false,
        miniCalendar: false,
        typeInvite: false,
        selectedDate: moment(new Date()).format('YYYY-MM-DD'),
        horariosLivres: {},
        quadras: [],
        color: '#fff',
    }),
    computed: {
        whatsInviteText: function() {
            let horariosLivresStr = ''

            if ( this.typeInvite
                 && !!this.quadras.find(q => q.invite.length)
            )
                horariosLivresStr = '\n\n'
                    + `Horários livres em ${this.formatedDate}:`
                    + this.quadras.filter(q => q.invite.length)
                        .map(q =>
                            `\n\n  ${q.nome}:`
                            + this.horariosLivres[q.id]
                                .filter(h => this.filterSchedule(h))
                                .map(h => (moment(h.hora_inicio, 'HH:mm').isAfter(moment(new Date())) || this.formatedDate != (moment(new Date()).format('DD/MM/YYYY'))) ?
                                    `\n  \t${h.hora_inicio} - (${h.duracao}min)` : ``
                                )
                        ) + '\n'

            horariosLivresStr = horariosLivresStr.replace(/,/g, '')

            let msgLink = ''
            if (this.estabelecimento.id_url) {
                msgLink = '\nPara fazer sua reserva acesse o link: '
                + `https://zumer.com.br/${this.estabelecimento.id_url}`
            }

            return window.encodeURIComponent(
                `Confira os horários disponíveis de ${this.estabelecimento.nome}.`
                + horariosLivresStr
                + msgLink
            )
        },
        formatedDate: {
            get: function() {
                return moment(this.selectedDate).format('DD/MM/YYYY')
            },
            set: function(date) {
                this.$store.commit('selectedDate', moment(date).format('YYYY-MM-DD'))
            },
        },
    },
    watch: {
        openFlag: function() {
            this.openViewModel = true
        },
        dialogInviteOptions: function() {
            this.typeInvite = false
            if (this.dialogInviteOptions) this.fetchHorarios()
        },
    },
    mounted() {
        window.addEventListener('popstate', this.popstate)
    },
    destroyed() {
        window.removeEventListener('popstate', this.popstate, false)
    },
    methods: {
        getCalendarDate(){
            this.selectedDate = sessionStorage.getItem('dataSelct')
        },
        popstate(){
            this.openViewModel = false
        },
        actionLogout: function() {
            this.activeFlag++
        },
        confirmLogout: function() {
            this.$emit('logout')
        },
        fetchHorarios: function () {
            this.$http({
                method: 'post',
                url: '/apiweb/agenda',
                data: {
                    data: this.selectedDate,
                }
            }).then(response => {
                this.horariosLivres = response.data.horariosLivres
                this.quadras = response.data.quadras
                    .map(q => ({ ...q, shifts2share: [], invite: [] }))
                this.typeInvite = true
            })
        },
        courtChange: function (eventPayload) {
            if (eventPayload.length)
                this.quadras.filter(q => q.id === eventPayload[0])
                    .forEach(q => {
                        q.shifts2share = []
                        if (!this.isPastSchedule(0)) q.shifts2share.push('0')
                        if (!this.isPastSchedule(1)) q.shifts2share.push('1')
                        if (!this.isPastSchedule(2)) q.shifts2share.push('2')
                    })
            else
                this.quadras.filter(q => !q.invite.length)
                    .forEach(q => q.shifts2share = [])
        },
        toogleAllShift: function(shift) {
            if (this.isPastSchedule(shift)) return

            if (!this.quadras.find(q => q.shifts2share.includes(`${shift}`)))
                this.quadras.filter(q => q.invite.length)
                    .forEach(q => q.shifts2share.push(`${shift}`))
            else
                this.quadras.filter(q => q.invite.length)
                    .forEach(q => {
                        let i = q.shifts2share.indexOf(`${shift}`)
                        if (i !== -1)
                            q.shifts2share.splice(i, 1)
                    })
        },
        toogleAllCourts: function() {
            if (!!this.quadras.find(q => !q.invite.length))
                this.quadras.forEach(q => {
                    q.invite = [q.id]
                    q.shifts2share = []
                    if (!this.isPastSchedule(0)) q.shifts2share.push('0')
                    if (!this.isPastSchedule(1)) q.shifts2share.push('1')
                    if (!this.isPastSchedule(2)) q.shifts2share.push('2')
                })
            else
                this.quadras.forEach(q => {
                    q.invite = []
                    q.shifts2share = []
                })
        },
        filterSchedule: function(h) {
            let f = 'hh:mm:ss'
            if (this.quadras.find(q => q.id === h.quadra_id).shifts2share.includes('0'))
                if (moment(h.hora_inicio, f).isBetween(
                    moment('00:00:00', f),
                    moment('12:00:00', f),
                    null, '[)'))
                    return true
            if (this.quadras.find(q => q.id === h.quadra_id).shifts2share.includes('1'))
                if (moment(h.hora_inicio, f).isBetween(
                    moment('12:00:00', f),
                    moment('18:00:00', f),
                    null, '[)'))
                    return true
            if (this.quadras.find(q => q.id === h.quadra_id).shifts2share.includes('2'))
                if (moment(h.hora_inicio, f).isBetween(
                    moment('18:00:00', f),
                    moment('24:00:00', f),
                    null, '[)'))
                    return true

            return false
        },
        isPastSchedule: function(shift) {
            let f = 'hh:mm:ss'

            if (moment(this.selectedDate, '').isAfter(moment()))
                return false
            if (moment(this.selectedDate, '').isBefore(moment(), 'day'))
                return true
            if (shift === 0 && moment().isAfter(moment('12:00:00', f)))
                return true
            if (shift === 1 && moment().isAfter(moment('18:00:00', f)))
                return true
            return false
        },
        updateShift2share: function(q) {
            q.invite = [q.id]
            if(q.shifts2share.length === 0)
                q.invite = []
        }
    }
}
</script>

<style lang="sass" scoped>
    @import '@assets/css/global-vars.sass'

    .v-input--dense::v-deep
        margin-top: 2px
        margin-bottom: 7px

        label
            display: inline-block
            margin-left: 3px
            font-size: 14px
            font-weight: 500

        &.no-label
            display: inline-block
            margin-right: 0 !important

            .v-input--selection-controls__input
                margin-right: 0 !important

    ::v-deep .modal-invite .v-card__title
        display: block
        max-width: 100%
        white-space: nowrap
        overflow-x: hidden
        text-overflow: ellipsis

    .v-data-table th
        white-space: nowrap

        > *
            vertical-align: middle

    @media (max-width: 400px)
        .v-card__actions
            flex-wrap: wrap

            > *
                width: 100%
                margin: 10px 0 !important

    @media (max-width: 500px)
        .modal-share
            .v-data-table td:first-child,
            .v-data-table th
                padding: 0 5px

            .v-card__text
                margin: 0
                padding: 0

            .v-data-table td:first-child,
            .v-data-table th:first-child
                padding-left: 0

            .v-data-table td:last-child,
            .v-data-table th:last-child
                padding-right: 0
</style>